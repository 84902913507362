

















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component
export default class JTLTransferComponent extends BaseComponent {
    @Prop()
    transferData: any;
    @Prop()
    keys: string[];

    targetKeys: string[] = [];
    targetData: any = [];

    created() {
        this.targetKeys = this.keys;
    }

    handleChange(nextTargetKeys, direction, moveKeys) {
        this.targetKeys = nextTargetKeys;
        this.targetData = this.transferData.filter(item => { return this.targetKeys.indexOf(item.key) > -1; });
    }
}

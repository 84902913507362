import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlTableModel, FormControlDateModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import CommonService from '@common-src/service/common';
import { Moment } from 'moment';
import { PropertyEntityType } from '@common-src/model/enum';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';

export class CleaningEntityModel extends BaseEntityModel {
    @FormControl({
        label: '保洁计划',
        type: FormControlType.TEXT,
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '保洁周期',
        type: FormControlType.SELECT,
        required: true,
        optionsPromise: CommonService.getTimePlanList,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlOptionModel)
    timePlanId: string = undefined;
    timePlanIdName: string = undefined;

    @FormControl({
        label: '有效日期',
        type: FormControlType.SELECT_DATERANGE,
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlDateModel)
    dateRange: Array<Moment> = undefined;
    beginDate: string = undefined;
    endDate: string = undefined;

    @FormControl({
        label: '是否启用',
        type: FormControlType.RADIO_GROUP,
        options: [{ name: '是', value: true },
            { name: '否', value: false }],
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlTextModel)
    useFlag: boolean = true;

    @FormControl({
        label: '保洁等级',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.WORK_ORDER_LEVEL_CONF,
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlOptionModel)
    level: string = undefined;

    @FormControl({
        label: '关联工单',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getAllWorkOrderOptions,
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlOptionModel)
    relationId: string = undefined;
    // linkedName:string = undefined;

    @FormControl({
        label: '保洁楼层',
        type: FormControlType.SELECT_SPACE,
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlOptionModel)
    position: string = undefined;

    @FormControl({
        label: '保洁区域',
        type: FormControlType.TEXT_AREA,
        required: true,
        rows: 3,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlTextModel)
    positionDescription: string = undefined;

    @FormControl({
        label: '保洁内容',
        type: FormControlType.TEXT_AREA,
        required: true,
        rows: 10,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlTextModel)
    content: string = undefined;

    statusName: string = undefined;
    createUserName: string = undefined;
    createdTime: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '保洁周期',
                dataIndex: 'timePlanIdName'
            },
            {
                title: '创建人',
                dataIndex: 'createUserName'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '状态',
                dataIndex: 'statusName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class CleaningQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    @QueryControl({
        label: '状态',
        type: QueryControlType.SELECT,
        options: [
            {
                value: 'ALL',
                name: '全部'
            },
            {
                value: 'ON',
                name: '启用'
            },
            {
                value: 'OFF',
                name: '禁用'
            },
            {
                value: 'EXPIRED',
                name: '过期'
            }
        ]
    })
    status: string ='ALL';
    toService() {
        const data = super.toService();
        if (this.name || this.status) {
            data.params = {
                name: this.name,
                status: this.status
            };
            delete data.name;
            delete data.status;
        }

        return data;
    }
}

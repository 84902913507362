






















































import { Component, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import TableComponent from '@common-src/mixins/table-component';
import { CleaningEntityModel, CleaningQueryModel } from '@common-src/entity-model/cleaning-entity';
import CleaningService from '@common-src/service/cleaning';
import CleaningDetailComponent from '@/pages/dashboard/cleaning/cleaning-detail.vue';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        'cleaning-detail-component': CleaningDetailComponent
    }
})
export default class CleaningListComponent extends Mixins(TableComponent, DrawerComponent) {
    CleaningEntityModel = CleaningEntityModel;
    ViewModeType = ViewModeType;
    selectedRecord = '';
    viewType = ViewModeType.VIEW;

    created() {
        this.startFullScreenLoading();
        this.initTable({
            service: CleaningService,
            queryModel: new CleaningQueryModel(),
            tableColumns: CleaningEntityModel.getTableColumns()
        });
        this.getList();
        this.width = '50%';
        this.stopFullScreenLoading();
    }

    editClick(record, viewType) {
        this.selectedRecord = record;
        this.viewType = viewType;
        this.drawerOpen(record);
    }

    showDrawer() {
        this.getList();
        this.drawerVisible = false;
    }
}

